/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/assets/styles/layout.css"
import "./src/assets/styles/fonts.css"

import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import * as Sentry from "@sentry/gatsby"

Sentry.init({
  dsn: "https://eb7d7d6d262c485265b45473fc2578cf@o4508647206486016.ingest.de.sentry.io/4508697643581520",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [window.location.origin, /multi-consult/i],
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  tracePropagationTargets: [window.location.origin, /multi-consult/i],
  // Session Replay
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
})

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LehCZ4pAAAAAC6pwdikY_-v6a7boFKGwNGJzLPe">
    {element}
  </GoogleReCaptchaProvider>
)
